import { useCallback } from 'react';
import { ClientContactRequest, ClientContactResponse } from '../../models/ClientContacts';
import TagSelector from '../tags/TagSelector';
import { Input } from '../shared/form-control/Input';
import PhoneInputWithCountrySelect from 'react-phone-number-input';
import CountryCodeSelect from '../shared/CountryCodeSelect';
import { useTranslation } from 'react-i18next';
import { PhoneNumberInput } from '../form/action-types/phone-number/PhoneNumberAction';
import { ClientTagResponse } from '../../models/ClientTag';

type Props<TEntity> = {
  contact: TEntity;
  onChange: (contact: TEntity) => void;
  onTagsChanged: (tags: ClientTagResponse[]) => void;
};

const ContactGeneralDetails = <TEntity extends ClientContactResponse | ClientContactRequest>(props: Props<TEntity>) => {
  const { contact, onChange, onTagsChanged } = props;

  const { t } = useTranslation('organisation');

  const setValue = useCallback(
    (key: keyof typeof contact | keyof typeof contact.properties, value: string) => {
      if (key in contact) {
        onChange({ ...contact, [key]: value });
      } else {
        onChange({ ...contact, properties: { ...contact.properties, [key]: value } });
      }
    },
    [contact, onChange],
  );

  return (
    <>
      <Input
        label={t('contacts.add-contact-modal.fields.first-name')}
        placeholder={t('contacts.add-contact-modal.fields.first-name')}
        required
        value={contact.firstName}
        onChange={(e) => setValue('firstName', e.target.value)}
      />

      <Input
        label={t('contacts.add-contact-modal.fields.last-name')}
        placeholder={t('contacts.add-contact-modal.fields.last-name')}
        required
        value={contact.lastName}
        onChange={(e) => setValue('lastName', e.target.value)}
      />

      <Input
        label={t('contacts.add-contact-modal.fields.email')}
        placeholder={t('contacts.add-contact-modal.fields.email')}
        required
        value={contact.email}
        onChange={(e) => setValue('email', e.target.value)}
      />

      <div className="text-color-3 text-dpm-12 mt-4 transition-opacity duration-150 ease-in-out">{t('contacts.add-contact-modal.fields.phone')}</div>
      <PhoneInputWithCountrySelect
        international
        defaultCountry={'DE'}
        value={contact.properties.phoneNumber}
        onChange={(value) => setValue('phoneNumber', value || '')}
        countrySelectComponent={(props) => (
          <div className="w-28">
            <CountryCodeSelect {...props} menuPosition="left" flagOnly={true} className={`rounded-r-none border-r-0`} />
          </div>
        )}
        inputComponent={PhoneNumberInput}
        numberInputProps={{ isValid: true }}
        className="flex items-center"
      />

      <Input
        label={t('contacts.add-contact-modal.fields.company')}
        placeholder={t('contacts.add-contact-modal.fields.company')}
        value={contact.properties.company}
        onChange={(e) => setValue('company', e.target.value)}
      />

      <Input
        label={t('contacts.add-contact-modal.fields.job-title')}
        placeholder={t('contacts.add-contact-modal.fields.job-title')}
        value={contact.properties.jobTitle}
        onChange={(e) => setValue('jobTitle', e.target.value)}
      />

      <TagSelector
        selectedTagIds={'tags' in contact ? contact.tags.map((tag) => tag.id) : undefined}
        className="mt-8"
        label={t('contacts.add-contact-modal.fields.tags')}
        placeholder={t('contacts.add-contact-modal.fields.tags-example')}
        onTagsChanged={onTagsChanged}
        helpText={t('contacts.add-contact-modal.fields.tags-help')}
        canEditTags
      />
    </>
  );
};

export default ContactGeneralDetails;
